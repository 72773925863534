const platform = "master"
// const platform = "test"
// const platform = "wyf"
var singleUrl = platform === "master" ? "https://htgl.xiaoliy.com/" : "http://192.168.0.129:90/"
var baseUrl = platform === "master" ? "https://htgl.xiaoliy.com/index/" : "http://192.168.0.129:90/index/"
const upUrl = 'https://fangdian.oss-cn-hangzhou.aliyuncs.com'
if (platform === "master") {
	baseUrl = "https://htgl.xiaoliy.com/index/"
} else if (platform === "test") {
	baseUrl = "http://192.168.0.129:90/index/"
} else if (platform === "wyf") {
	baseUrl = "http://192.168.0.129:90/index/"
}

export {
	platform,baseUrl,upUrl,singleUrl
}